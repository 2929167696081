// src/metaData.js
const productMetaData = {
    "eb69370c-14f5-465a-8974-a2b3f8de5fb5": {
        title: "Buy Multimeds Rose Glycerin 500+75g Free with Pump Offer",
        description: "Get Multimeds Rose Glycerin 500g + 75g free with pump! Perfect for skin hydration and nourishment. Limited offer, order now for soft, glowing skin with natural ingredients.",
        url: "https://www.mymultimeds.com/product/eb69370c-14f5-465a-8974-a2b3f8de5fb5",
    },
    "5db28835-57e9-4bfe-bf83-ef0fabf8385b": {
        title: "Multimeds Rose Glycerin 500g + 75g Free with Pump",
        description: "Nourish and hydrate your skin with Multimeds Rose Glycerin 500g + 75g free with pump. Enjoy soft, glowing skin with natural rose essence. Order now for this special offer!",
        url: "https://www.mymultimeds.com/product/5db28835-57e9-4bfe-bf83-ef0fabf8385b",
    },
    "2fc538ba-2ba9-475f-b741-b4bd1bf75ec3": {
        title: "Multimeds Rose Glycerin 500g + 75g Free with Pump – Buy Now!",
        description: "Get Multimeds Healthcare Rose Glycerin 500g + 75g free with pump. Perfect for hydrating and nourishing your skin with natural rose essence. Limited-time offer, buy now!",
        url: "https://www.mymultimeds.com/product/2fc538ba-2ba9-475f-b741-b4bd1bf75ec3",
    },
    "13d51f51-a4cf-4297-989f-f6081101bd4d": {
        title: "Multimeds Rose Glycerin 500g + 75g Free with Pump Offer",
        description: "Enjoy Multimeds Rose Glycerin 500g with 75g free and pump. Ideal for deep skin hydration and nourishment with natural rose essence. Limited-time offer, shop now!",
        url: "https://www.mymultimeds.com/product/13d51f51-a4cf-4297-989f-f6081101bd4d",
    },
    "91d43630-d65d-415e-8e9d-efaad50ec42c": {
        title: "Multimeds Rose Glycerin 500g + 75g Free with Pump",
        description: "Get Multimeds Rose Glycerin 500g with 75g free and pump. Perfect for skin hydration and nourishment with natural rose essence. Limited-time offer—order today!",
        url: "https://www.mymultimeds.com/product/91d43630-d65d-415e-8e9d-efaad50ec42c",
    },
    "f4010cc7-a2bd-4d8f-b567-c91a74b70553": {
        title: "Multimeds Rose Glycerin 500+75g with Free Pump - Moisturizer",
        description: "Multimeds Healthcare Rose Glycerin offers deep hydration. This 500g bottle includes a 75g bonus and a complimentary pump for convenient application. Ideal for relaxing and hydrating the skin.",
        url: "https://www.mymultimeds.com/product/f4010cc7-a2bd-4d8f-b567-c91a74b70553",
    },
    "10d8cecb-9494-430f-826c-556f01bf42d3": {
        title: "Multimeds Rose Glycerin 500g + 75g Bonus & Free Pump",
        description: "Multimeds Healthcare Rose Glycerin 500g, including 75g bonus and free pump. Ideal for deep hydration and skin relaxing. Enjoy a smoother and more moisturized complexion.",
        url: "https://www.mymultimeds.com/product/10d8cecb-9494-430f-826c-556f01bf42d3",
    },
    "01620089-e587-43af-88aa-e93236e5f512": {
        title: "Multimeds Rose Glycerin 500g + 75g Pump Included",
        description: "Multimeds Rose Glycerin 500g with 75g bonus and pump included. Ideal for deep skin hydration and nourishment. Enjoy a smooth, moisturized complexion with this convenient size!",
        url: "https://www.mymultimeds.com/product/01620089-e587-43af-88aa-e93236e5f512",
    },
    "c66d34ed-6af5-4049-a7f4-0f24623c0d37": {
        title: "Multimeds 500g Rose Glycerin + 75g Free with Pump",
        description: "Get 75g Rose Glycerin Free with Pump from Multimeds Healthcare! Enjoy the moisturizing benefits of glycerin with a free extra, perfect for soft, healthy skin. Limited offer.",
        url: "https://www.mymultimeds.com/product/c66d34ed-6af5-4049-a7f4-0f24623c0d37",
    },
    "97df6869-4a22-46e7-afce-47a9958b681a": {
        title: "Multimeds: 500g Rose Glycerin + 75g Free with Pump",
        description: "Multimeds Healthcare: Buy 500g Rose Glycerin and receive 75g free with pump! Enjoy smooth, moisturised skin with this exclusive deal. Ideal for daily skincare routines.",
        url: "https://www.mymultimeds.com/product/97df6869-4a22-46e7-afce-47a9958b681a",
    },
    "67eafbc2-7677-4e46-8927-923611915af7": {
        title: "Multimeds: 500g Rose Glycerin + 75g Free, with Pump",
        description: "Multimeds: Get 500g Rose Glycerin + 75g Free with Pump! Perfect for moisturizing and nourishing your skin. Grab this limited-time offer for healthy, glowing skin today!",
        url: "https://www.mymultimeds.com/product/67eafbc2-7677-4e46-8927-923611915af7",
    },
    "503e4757-9e27-4729-b69a-e95cc046bfa2": {
        title: "Multimeds: 500g Rose Glycerin + 75g Free & Pump Offer",
        description: "Multimeds: Buy 500g Rose Glycerin and Get 75g Free with Pump! Enjoy this special offer for superior hydration and skincare. Limited time only—shop now!",
        url: "https://www.mymultimeds.com/product/503e4757-9e27-4729-b69a-e95cc046bfa2",
    },
    "2bb4b9b-002a-4c3a-bfac-24c64d374ab9": {
        title: "Multimeds: 500g Rose Glycerin + 75g Free, Pump Included",
        description: "Multimeds Healthcare Rose glycerin 500+75g free with pump.",
        url: "https://www.mymultimeds.com/product/2bb4b9b-002a-4c3a-bfac-24c64d374ab9",
    },
    "3fd6eba8-4a90-4b5f-b959-13bebe15f8b": {
        title: "Multimeds: Buy 500g Rose Glycerin and Get 75g Free with Pump",
        description: "Multimeds: Buy 500g Rose Glycerin and Get 75g Free with Pump! Enjoy luxurious hydration and skincare with this exclusive offer. Limited time only—act fast!",
        url: "https://www.mymultimeds.com/product/3fd6eba8-4a90-4b5f-b959-13bebe15f8b",
    },
    "416aba67-b93a-43b3-892b-6932e02edb59": {
        title: "Multimeds: Get 500g Rose Glycerin and Receive 75g Free with Pump!",
        description: "Multimeds: Get 500g Rose Glycerin and Receive 75g Free with Pump! Perfect for enhanced skincare and hydration. Don’t miss out on this exclusive offer—limited time only.",
        url: "https://www.mymultimeds.com/product/416aba67-b93a-43b3-892b-6932e02edb59",
    },
    "4ab01795-aaa5-4c4f-b801-296ec45fa38b": {
        title: "Multimeds: Buy 500g Rose Glycerin + 75g Free, Pump Gift!",
        description: "Multimeds: Buy 500g Rose Glycerin and Get 75g Free with Pump Gift! Enjoy this special offer for enhanced skincare and hydration. Limited time only—shop now!",
        url: "https://www.mymultimeds.com/product/4ab01795-aaa5-4c4f-b801-296ec45fa38b",
    },
    "6bf54abe-39f7-4e33-b6f5-bc01eaedf38d": {
        title: "Multimeds: Purchase 500g Rose Glycerin and Get 75g Free with Pump Bonus",
        description: "Multimeds: Purchase 500g Rose Glycerin and Get 75g Free with a Pump Bonus! Experience superior hydration and skincare with this exclusive offer. Limited time only!",
        url: "https://www.mymultimeds.com/product/6bf54abe-39f7-4e33-b6f5-bc01eaedf38d",
    },
    "7a00104e-d01b-4460-9b77-6d2a32f47080": {
        title: "Multimeds: Buy 500g Rose Glycerin + 75g Free with Pump!",
        description: "Multimeds: Buy 500g Rose Glycerin and get 75g free with a pump! Ideal for deeply moisturising and toning skin. Take advantage of this limited time offer now!",
        url: "https://www.mymultimeds.com/product/7a00104e-d01b-4460-9b77-6d2a32f47080",
    },
    "10025736-5725-4981-bba8-312f72fa6500": {
        title: "Multimeds: Get 500g Rose Glycerin + 75g Free with Pump at Multimeds",
        description: "Get 500g Rose Glycerin and Receive 75g Free with Pump at Multimeds! Enjoy this exclusive offer for superior hydration and skincare. Limited time only—shop now!",
        url: "https://www.mymultimeds.com/product/10025736-5725-4981-bba8-312f72fa6500",
    },
    "3b4da4b8-018e-4498-991c-4dbeb7484e0": {
        title: "Buy 500g Rose Glycerin + 75g Free with Pump – Multimeds",
        description: "500g Rose Glycerin + 75g Free with Pump at Multimeds! Enjoy premium skincare with this exclusive offer. Limited time only—boost your hydration routine today!",
        url: "https://www.mymultimeds.com/product/3b4da4b8-018e-4498-991c-4dbeb7484e0",
    },
    "20fa3db7-4ca6-4df1-b723-41656564cde2": {
        title: "Multimeds: Secure 500g Rose Glycerin + 75g Free & Pump Deal",
        description: "Multimeds: Secure 500g Rose Glycerin + 75g Free & Pump! This premium skincare offer provides exceptional value and hydration. Limited-time promotion—shop now!",
        url: "https://www.mymultimeds.com/product/20fa3db7-4ca6-4df1-b723-41656564cde2",
    },
    "9502b6b4-49ab-41fd-b65e-39fc790d6df1": {
        title: "Multimeds: 500g Rose Glycerin + 75g Free & Pump Special",
        description: "Multimeds: Buy 500g Rose Glycerin and Get 75g Free with a Pump! Enjoy this special offer for superior hydration and skincare. Limited time only—shop now!",
        url: "https://www.mymultimeds.com/product/9502b6b4-49ab-41fd-b65e-39fc790d6df1",
    },
    "ff1b302a-9f42-4ad4-a30e-d2a390cf4c7b": {
        title: "Multimeds: Purchase 500g Rose Glycerin and Get 75g Free with a Pump Bonus",
        description: "Multimeds: Purchase 500g Rose Glycerin and Get 75g Free with a Pump Bonus! Elevate your skincare routine with this special offer. Limited time only—order now!",
        url: "https://www.mymultimeds.com/product/ff1b302a-9f42-4ad4-a30e-d2a390cf4c7b",
    },
    "a8726088-74cc-4795-93a2-b138962c6278": {
        title: "500g Rose Glycerin + 75g Free with Pump – Multimeds Deal",
        description: "500g Rose Glycerin + 75g Free with Pump – Multimeds! Boost your skincare routine with this amazing offer. Limited stock—grab yours before it’s gone!",
        url: "https://www.mymultimeds.com/product/a8726088-74cc-4795-93a2-b138962c6278",
    },
    "96acae33-4e5e-44bb-9c80-3b3b557b120b8": {
        title: "500g Rose Glycerin + 75g Free & Pump – Multimeds Deal",
        description: "500g Rose Glycerin + 75g Free & Pump – Multimeds Deal. Take advantage of this limited-time offer for superior hydration and skincare. Act fast—limited stock available!",
        url: "https://www.mymultimeds.com/product/96acae33-4e5e-44bb-9c80-3b3b557b120b8",
    },
    "a02c8b0b-90e9-44dd-8c2f-564645a4675b": {
        title: "500g Rose Glycerin + 75g Free & Pump Offer",
        description: "Take advantage of the Multimeds deal: Purchase 500g Rose Glycerin and get an additional 75g free with a pump! Enhance your skincare routine with this offer now.",
        url: "https://www.mymultimeds.com/product/a02c8b0b-90e9-44dd-8c2f-564645a4675b",
    },
    "f69b56cb-cd48-478a-804b-469105172cbd": {
        title: "Multimeds Special: Purchase 500g Rose Glycerin and Get 75g Free with Pump!",
        description: "Multimeds Special: Purchase 500g Rose Glycerin and get 75g free with an included pump! Enhance your skincare routine with this exclusive offer. Shop now!",
        url: "https://www.mymultimeds.com/product/f69b56cb-cd48-478a-804b-469105172cbd",
    },
    "8e8f9a7a-7038-475a-bf82-04aef11c618c": {
        title: "Multimeds Special: Get 500g Rose Glycerin with an additional 75g free and a pump!",
        description: "Multimeds Special: Get 500g Rose Glycerin with an additional 75g free and a pump! Enhance your skincare routine with this exclusive deal. Act fast—limited stock available!",
        url: "https://www.mymultimeds.com/product/8e8f9a7a-7038-475a-bf82-04aef11c618c",
    },
    "03b8cd96-af6c-4686-961d-41b97a902967": {
        title: "Multimeds: Get 500g Rose Glycerin + 75g Free & Pump Deal",
        description: "Take advantage of Multimeds' special promo: 500g Rose Glycerin plus 75g free and a pump! Ideal for nourishing and hydrating your skin. Shop this limited-time deal today!",
        url: "https://www.mymultimeds.com/product/03b8cd96-af6c-4686-961d-41b97a902967",
    },
    "814fd605-ab7a-4418-b02a-ef42016714c4": {
        title: "Don’t miss the Multimeds sale: 500g Rose Glycerin plus 75g free and a pump!",
        description: "Don’t miss the Multimeds sale: 500g Rose Glycerin plus 75g free and a pump! Upgrade your skincare with this special offer. Limited availability—shop now!",
        url: "https://www.mymultimeds.com/product/814fd605-ab7a-4418-b02a-ef42016714c4",
    },
    "e6c087a1-abc1-4a43-9891-365f0355f8c5": {
        title: "Multimeds Gift: Buy 500g Rose Glycerin and Get an Extra 75g Free with Pump",
        description: "Enjoy the Multimeds gift: Buy 500g Rose Glycerin and get an extra 75g with a pump included! Perfect for luxurious, hydrating skincare. Limited time only.",
        url: "https://www.mymultimeds.com/product/e6c087a1-abc1-4a43-9891-365f0355f8c5",
    },
    "e40798a9-d690-4c20-ad6c-8fd3cdfc3553": {
        title: "Unlock the Multimeds bonus: 500g Rose Glycerin with 75g Free and a Pump!",
        description: "Unlock a special offer from Multimeds: 500g Rose Glycerin with an extra 75g free, plus a bonus pump! Enhance your skincare routine with this limited-time deal.",
        url: "https://www.mymultimeds.com/product/e40798a9-d690-4c20-ad6c-8fd3cdfc3553",
    },
    "404fbd19-9def-41ab-af76-e237b56e8321": {
        title: "Discover the Multimeds sale: 500g Rose Glycerin and Get 75g Free with Pump",
        description: "Discover the Multimeds sale: Buy 500g Rose Glycerin and Get 75g free with a pump included! Perfect for enhancing your skincare routine. Limited-time offer—order today!",
        url: "https://www.mymultimeds.com/product/404fbd19-9def-41ab-af76-e237b56e8321",
    },
    "e650fe39-2821-4780-a040-f0602f42bfeb": {
        title: "Enjoy the Multimeds discount: 500g Rose Glycerin and Get 75g Free with Pump!",
        description: "Grab this Multimeds discount: 500g Rose Glycerin, 75g free, plus a pump. Ideal for achieving radiant skin with added value and convenience.",
        url: "https://www.mymultimeds.com/product/e650fe39-2821-4780-a040-f0602f42bfeb",
    },
    "eb458359-313c-4d2f-8bdc-8e6cefc20c8e": {
        title: "Multimeds Gift: 500g Rose Glycerin + 75g Free with Pump",
        description: "Enjoy 500g Rose Glycerin with 75g free and a pump included, exclusively at Multimeds! Ideal for radiant, hydrated skin. Don’t miss out on this gift!",
        url: "https://www.mymultimeds.com/product/eb458359-313c-4d2f-8bdc-8e6cefc20c8e",
    },
    "34bbe7e1-c0ca-4565-84c4-7e04fa4e6d18": {
        title: "Special offer from Multimeds: 500g Rose Glycerin, 75g free, and a bonus pump!",
        description: "Special offer from Multimeds: 500g Rose Glycerin, 75g free, and a bonus pump! Ideal for radiant skin and hydration. Hurry—this deal is for a limited time only.",
        url: "https://www.mymultimeds.com/product/34bbe7e1-c0ca-4565-84c4-7e04fa4e6d18",
    },
    "3f883007-80ae-4adf-9ccc-57f2a99b4968": {
        title: "Multimeds offers 500g of Rose Glycerin with a bonus 75g free and a gift pump!",
        description: "Multimeds offers 500g of Rose Glycerin with a bonus 75g free and a gift pump! Ideal for hydrated, glowing skin. Limited-time gift—shop now and save!",
        url: "https://www.mymultimeds.com/product/3f883007-80ae-4adf-9ccc-57f2a99b4968",
    },
    "21597cf2-c85b-403b-93ba-4a5cdae5c61a": {
        title: "Unlock a special offer from Multimeds: 500g Rose Glycerin with 75g Free and Pump",
        description: "Unlock a special offer from Multimeds: 500g Rose Glycerin with an additional 75g free, plus a bonus pump! Enhance your skincare routine with this exclusive deal.",
        url: "https://www.mymultimeds.com/product/21597cf2-c85b-403b-93ba-4a5cdae5c61a",
    },
    "bd8f4a5c-e59b-4e4b-9359-277756aa866c": {
        title: "500g Rose Glycerin + 75g Free with Pump – Multimeds Promo",
        description: "Take advantage of Multimeds' promotion: 500g Rose Glycerin plus 75g free and a free pump. Enhance your skincare routine with this exclusive gift offer.",
        url: "https://www.mymultimeds.com/product/bd8f4a5c-e59b-4e4b-9359-277756aa866c",
    },
    "36ce8991-5f63-4c7b-acd2-1b21a6ae3e82": {
        title: "500g Rose Glycerin + 75g Free with Pump Gift",
        description: "Take advantage of Multimeds' promotion: 500g Rose Glycerin plus 75g free and a free pump. Enhance your skincare routine with this exclusive gift offer.",
        url: "https://www.mymultimeds.com/product/36ce8991-5f63-4c7b-acd2-1b21a6ae3e82",
    },
    "4a390365-7989-41d4-86f7-8eef2b2f9a83": {
        title: "Multimeds: 500g Rose Glycerin + 75g Free with Pump – Multimeds Offer",
        description: "Take advantage of Multimeds' offer: Get 500g of premium Rose Glycerin with an extra 75g free and a complimentary pump. Perfect for radiant, hydrated skin.",
        url: "https://www.mymultimeds.com/product/4a390365-7989-41d4-86f7-8eef2b2f9a83",
    },
    "4e2108d8-e253-489d-af80-3c8a13663a8f": {
        title: "Multimeds Special: 500g Rose Glycerin + 75g Free & Pump – Multimeds Discount",
        description: "Grab this Multimeds discount: 500g Rose Glycerin, 75g free, plus a pump. Ideal for achieving radiant skin with added value and convenience.",
        url: "https://www.mymultimeds.com/product/4e2108d8-e253-489d-af80-3c8a13663a8f",
    }
};

export default productMetaData;
